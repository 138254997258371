import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'

import styles from './BackgroundImage.module.scss'

const IMGIX_DOMAIN = process.env.NEXT_PUBLIC_IMGIX_URL

const DEVICES = {
    NONE: 'none',
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop'
}

const DEVICE_FILE_SPECIFIERS = {
    NONE: '',
    UNIVERSAL: 'u-',
    MOBILE: 'm-',
    TABLET: 't-',
    DESKTOP: 'd-'
}

const useGetDevicePixelRatio = (useDpr) => {
    const [devicePixelRatio, setDevicePixelRatio] = useState('1')

    useEffect(() => {
        if (useDpr) {
            const mediaQuery = window?.matchMedia(`
                @media screen, screen
                and (min-resolution: 192dpi)
                and (resolution: ${window.devicePixelRatio}dppx)
            `)

            const handleDevicePixelRatioChange = () => {
                mediaQuery?.matches ? setDevicePixelRatio('2') : setDevicePixelRatio('1')
            }

            handleDevicePixelRatioChange()

            mediaQuery?.addEventListener?.('change', handleDevicePixelRatioChange)

            return () => {
                mediaQuery?.removeEventListener?.('change', handleDevicePixelRatioChange)
            }
        }
    }, [useDpr])

    return devicePixelRatio
}

const getSpecifier = (device) => {
    if (!device) {
        return null
    }

    return DEVICE_FILE_SPECIFIERS[device.toUpperCase()]
}

const getImagePrefix = ({ isMobile, isTablet, isUniversal, excludeTablet, prefixOverride }) => {
    if (isUniversal) return DEVICE_FILE_SPECIFIERS.UNIVERSAL
    if (isMobile) return getSpecifier(prefixOverride.mobile || 'mobile')
    if (isTablet && !excludeTablet) return getSpecifier(prefixOverride.tablet || 'tablet')
    return getSpecifier(prefixOverride.desktop || 'desktop')
}

const getImageWidth = ({ isMobile, isTablet, mobile, tablet, desktop, excludeTablet }) => {
    if (isMobile) return mobile
    if (isTablet && !excludeTablet) return tablet
    return desktop
}

const BackgroundImage = ({
    children,
    imgixDomain = IMGIX_DOMAIN,
    className,
    folder,
    name,
    type = 'jpg',
    isUniversal = false,
    widths = {
        mobile: 375,
        tablet: 744,
        desktop: 1440
    },
    breakpointWidths = {
        tablet: 768,
        desktop: 1025
    },
    excludeTablet = false,
    dataSelector,
    useDpr = true,
    prefixOverride = {}
}) => {
    const devicePixelRatio = useGetDevicePixelRatio(useDpr)
    const breakpoints = useBreakpoints('desktop', breakpointWidths.tablet, breakpointWidths.desktop)

    const prefix = getImagePrefix({ ...breakpoints, isUniversal, excludeTablet, prefixOverride })
    const width = getImageWidth({ ...breakpoints, ...widths, excludeTablet })
    const url = `https://${imgixDomain}/${folder}/${prefix}${name}.${type}?dpr=${devicePixelRatio}&auto=format&w=${width}`

    return (
        <div
            className={classNames(styles.backgroundImage, className)}
            style={{ backgroundImage: `url(${url})` }}
            data-selector={dataSelector}
        >
            {children}
        </div>
    )
}

BackgroundImage.propTypes = {
    imgixDomain: PropTypes.string,
    className: PropTypes.string,
    folder: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['jpg', 'png']),
    isUniversal: PropTypes.bool,
    widths: PropTypes.shape({
        mobile: PropTypes.number,
        tablet: PropTypes.number,
        desktop: PropTypes.number
    }),
    breakpointWidths: PropTypes.shape({
        tablet: PropTypes.number,
        desktop: PropTypes.number
    }),
    excludeTablet: PropTypes.bool,
    dataSelector: PropTypes.string,
    useDpr: PropTypes.bool,
    prefixOverride: PropTypes.shape({
        mobile: PropTypes.oneOf(Object.values(DEVICES)),
        tablet: PropTypes.oneOf(Object.values(DEVICES)),
        desktop: PropTypes.oneOf(Object.values(DEVICES))
    })
}

export default BackgroundImage
