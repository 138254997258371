import { SvgSustainability, SvgQuality, SvgWithoutMarkups } from './Svgs'

import styles from './WhySaatva.module.scss'

const reasons = [
    {
        Svg: SvgWithoutMarkups,
        heading: 'Crafted in America with uncompromising quality',
        description: 'Every Saatva luxury mattress is made to order and assembled in the U.S. with the highest quality, globally-sourced materials.',
        link: '/pricing',
        ctaText: 'Discover the difference'
    },
    {
        Svg: SvgSustainability,
        heading: 'Sustainability is at our core',
        description: 'From responsibly sourced materials to domestic manufacturing and delivery that reduce our carbon footprint, our entire business model has been eco-friendly from day one.',
        link: '/green-initiatives',
        ctaText: `See our green initiatives`
    },
    {
        Svg: SvgQuality,
        heading: 'Premium quality without the markups',
        description: `We cut out the middlemen so you get the highest quality at the best possible value. To get the same quality mattress elsewhere, you'd have to pay way more.`,
        link: '/saatva-vs-bed-in-a-box',
        ctaText: 'Don’t overpay for luxury'
    }
]

const WhySaatvaLegacy = () => {
    return (
        <section className={`${styles.whySaatva} section`} data-selector="whySaatva">
            <div className="container">
                <h2 className={`${styles.whySaatvaTitle} t-heading2 u-textCenter u-marginBottom--2xl`}>
                    Why Saatva?
                </h2>
                <div className="row">
                    {reasons.map(({ Svg, heading, description, link, ctaText }) => (
                        <div key={link} className={`${styles.tile} col col--xs-12 col--md-4`}
                        >
                            <div className={`row ${styles.row}`}>
                                <div className="col col--xs-12">
                                    <div className={styles.headingWrapper}>
                                        <div className={styles.iconWrapper}>
                                            <Svg className={styles.svg} />
                                        </div>
                                        <a href={link} data-selector={`heading-cta-${heading}`} className={`t-heading3 ${styles.title}`}>
                                            {heading}
                                        </a>
                                    </div>
                                    <div className={styles.description}>
                                        {description}
                                        <br />
                                        <a href={link} className={styles.cta} data-selector={`learn-more-cta-${heading}`}>{ctaText}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default WhySaatvaLegacy
