/* eslint-disable @next/next/no-html-link-for-pages */
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useBreakpoints } from '@saatva-bits/pattern-library.hooks.use-breakpoints'
import { Button } from '@saatva-bits/pattern-library.components.button'

import BackgroundImage from '@/components/BackgroundImage'
import WhySaatvaLegacy from './WhySaatvaLegacy'

import styles from './WhySaatva.module.scss'

const WhySaatva = ({ legacy = false }) => {
    const { isMobile } = useBreakpoints('mobile')

    if (legacy) return <WhySaatvaLegacy />

    const columnClassName = classNames('col--xs-12 col--md-6', {
        'u-paddingTop--3xs': isMobile
    })

    return (
        <section className={styles.whySaatvaV1} data-selector="whySaatva">
            <div className={styles.container}>
                {isMobile && (
                    <div className="row">
                        <div className="col--xs-12">
                            <a href="/green-initiatives" className={styles.tile}>
                                <BackgroundImage
                                    folder="homepage/why-saatva/sustainability"
                                    name="sustainability"
                                    className={styles.backgroundImage}
                                >
                                    <div className={styles.tileContent}>
                                        <h3>Sustainability is at our core</h3>
                                        <p>From responsibly sourced materials to domestic manufacturing and delivery that reduce our carbon footprint, our entire business model has been eco-friendly from day one.</p>
                                        <Button className={styles.tileContentCta} tabindex="-1">See our green initiatives</Button>
                                    </div>
                                </BackgroundImage>
                            </a>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className={classNames(columnClassName, { 'u-paddingRight--3xs': !isMobile })}>
                        <a href="/saatva-vs-bed-in-a-box" className={styles.tile}>
                            <BackgroundImage
                                folder="homepage/why-saatva/crafted-in-america"
                                name="crafted-in-america"
                                className={styles.backgroundImage}
                            >
                                <div className={styles.tileContent}>
                                    <h3>Crafted in America with uncompromising&nbsp;quality</h3>
                                    <p>Every Saatva luxury mattress is made to order and assembled in the U.S. with the highest quality, globally-sourced materials.</p>
                                    <Button className={styles.tileContentCta} tabindex="-1">Discover the difference</Button>
                                </div>
                            </BackgroundImage>
                        </a>
                    </div>
                    <div className={columnClassName}>
                        <a href="/pricing" className={styles.tile}>
                            <BackgroundImage
                                folder="homepage/why-saatva/premium-quality"
                                name="premium-quality"
                                className={styles.backgroundImage}
                            >
                                <div className={styles.tileContent}>
                                    <h3>Premium quality without the&nbsp;markups</h3>
                                    <p>We cut out the middlemen so you get the highest quality at the best possible value. To get the same quality mattress elsewhere, you'd have to pay way more.</p>
                                    <Button className={styles.tileContentCta} tabindex="-1">Don’t overpay for luxury</Button>
                                </div>
                            </BackgroundImage>
                        </a>
                    </div>
                </div>
                {!isMobile && (
                    <div className="row">
                        <div className="col--xs-12 u-paddingTop--3xs">
                            <a href="/green-initiatives" className={styles.tile}>
                                <BackgroundImage
                                    folder="homepage/why-saatva/sustainability"
                                    name="sustainability"
                                    className={classNames(styles.backgroundImage, styles.justifyContentCenter)}
                                >
                                    <div className={styles.tileContent}>
                                        <h3>Sustainability is at our core</h3>
                                        <p>From responsibly sourced materials to domestic manufacturing and delivery that reduce our carbon footprint, our entire business model has been eco-friendly from day one.</p>
                                        <Button className={styles.tileContentCta} tabindex="-1">See our green initiatives</Button>
                                    </div>
                                </BackgroundImage>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}

WhySaatva.propTypes = {
    legacy: PropTypes.bool
}

export default WhySaatva
